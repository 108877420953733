import {
    ExclamationTriangleIcon
} from '@heroicons/react/24/outline'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { signIn, signOut, useSession } from "next-auth/react"

export default function ErrorPage({ error, showLogin }) {
    const router = useRouter()

    return (
        <>
            <Head>
                <title>{error.code} - {error.message}</title>
                <meta name="description" content="An error has occurred" />
                <meta property="og:title" content={`${error.code} - ${error.message}`} />
                <meta property="og:description" content="An error has occurred" />
            </Head>

            <main className="grid min-h-full place-items-center bg-black px-6 py-24 sm:py-32 lg:px-8">
                <div className="text-center">
                    <p className="text-base font-semibold text-red-600"><ExclamationTriangleIcon className="w-20 h-20 m-auto justify-center" /></p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-100 sm:text-5xl">{error.code}</h1>
                    <p className="mt-2 text-base leading-7 text-gray-200">{error.message}</p>
                    <div className="mt-2 flex items-center justify-center gap-x-6">
                        {showLogin && (
                            <button className="text-md rounded-md text-white px-3 py-2 bg-white bg-opacity-20 hover:bg-opacity-30 transition" onClick={() => signIn("discord")}>
                                Login
                            </button>
                        ) || (
                            <a
                                className="transition rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer"
                                onClick={() => router.push('/')}
                            >
                                Go back home
                            </a>
                        )}
                    </div>
                </div>
            </main>
        </>
    )
}